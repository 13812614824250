import React, { useState, useRef } from 'react';
import axios from 'axios';
import Layout from '../components/Layout';

export default function MeetingAssistantPage() {
  const [notes, setNotes] = useState('');
  const [audioRecording, setAudioRecording] = useState(false);
  const [summaries, setSummaries] = useState([]);
  const recognitionRef = useRef(null);

  const handleStartMeeting = () => {
    if (!('webkitSpeechRecognition' in window)) {
      alert('Speech Recognition API is not supported in your browser.');
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.lang = 'en-US';
    recognition.continuous = true;

    recognition.onstart = () => {
      setAudioRecording(true);
      console.log('Speech recognition started.');
    };

    recognition.onresult = (event) => {
      let transcript = '';
      for (let i = event.resultIndex; i < event.results.length; i++) {
        transcript += event.results[i][0].transcript + ' ';
      }
      setNotes((prevNotes) => prevNotes + transcript);
    };

    recognition.onerror = (error) => {
      console.error('Speech recognition error:', error);
    };

    recognition.onend = () => {
      setAudioRecording(false);
      console.log('Speech recognition ended.');
    };

    recognitionRef.current = recognition;
    recognition.start();
  };

  const handleStopMeeting = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
    }
  };

  const handleGenerateSummary = async () => {
    if (!notes.trim()) {
      alert('Please write or record some notes before generating a summary.');
      return;
    }

    try {
      const response = await axios.post('http://localhost:5000/api/summarize', { notes });
      setSummaries(response.data.summary); // Set the summary points returned by the backend
    } catch (error) {
      console.error('Error generating summary:', error);
      alert('Failed to generate summary. Please try again.');
    }
  };

  return (
    <Layout>
      <div style={{ padding: '20px' }}>
        <h1>Meeting Assistant</h1>

        <section style={{ marginBottom: '20px' }}>
          <h2>Audio Recording</h2>
          <button onClick={handleStartMeeting} disabled={audioRecording} style={{ marginRight: '10px' }}>
            Start Meeting
          </button>
          <button onClick={handleStopMeeting} disabled={!audioRecording}>
            Stop Meeting
          </button>
        </section>

        <section style={{ marginBottom: '20px' }}>
          <h2>Meeting Notes</h2>
          <textarea
            placeholder="Notes will appear here..."
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            style={{ width: '100%', height: '150px', marginBottom: '10px' }}
          />
        </section>

        <section>
          <h2>Summary</h2>
          <button onClick={handleGenerateSummary} style={{ marginBottom: '10px' }}>
            Generate Summary
          </button>
          <ul>
            {summaries.map((summary, idx) => (
              <li key={idx}>{summary}</li>
            ))}
          </ul>
        </section>
      </div>
    </Layout>
  );
}
