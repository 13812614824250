import React, { useState, useRef } from 'react';
import Layout from '../components/Layout';
import axios from 'axios';

export default function InterviewPage() {
  const [userInput, setUserInput] = useState('');
  const [response, setResponse] = useState([]);
  const [isListening, setIsListening] = useState(false);
  const recognitionRef = useRef(null);

  const handleStartMeeting = () => {
    if (!('webkitSpeechRecognition' in window)) {
      alert('Speech Recognition API is not supported in your browser.');
      return;
    }
    const recognition = new window.webkitSpeechRecognition();
    recognition.lang = 'en-US';
    recognition.continuous = true;

    recognition.onstart = () => setIsListening(true);
    recognition.onresult = (event) => {
      let transcript = '';
      for (let i = event.resultIndex; i < event.results.length; i++) {
        transcript += event.results[i][0].transcript;
      }
      setUserInput((prevInput) => prevInput + ' ' + transcript);
    };
    recognition.onerror = (e) => console.error(e);
    recognition.onend = () => setIsListening(false);

    recognitionRef.current = recognition;
    recognition.start();
  };

  const handleStopMeeting = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setIsListening(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const res = await axios.post('http://localhost:5000/api/chat', { question: userInput });
      setResponse(res.data.answer);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>
      <div style={{ display: 'flex', gap: '20px' }}>
        <div style={{ flex: 1, padding: '20px', borderRight: '1px solid #ccc' }}>
          <h2>Interviewer ASK</h2>
          <button onClick={handleStartMeeting} disabled={isListening} style={{ margin: '5px' }}>
            Start Meeting
          </button>
          <button onClick={handleStopMeeting} disabled={!isListening} style={{ margin: '5px' }}>
            Stop Meeting
          </button>
          <textarea
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            placeholder="Type your question..."
            style={{ width: '100%', height: '100px', marginTop: '10px' }}
          />
          <button onClick={handleSubmit} style={{ marginTop: '10px' }}>
            Submit
          </button>
        </div>
        <div style={{ flex: 1, padding: '20px' }}>
          <h2>Edu AI Responses</h2>
          <ul>
            {response.map((point, idx) => (
              <li key={idx}>{point}</li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  );
}
