import React from 'react';
import { Link } from 'react-router-dom';

export default function Layout({ children }) {
  const headerStyle = {
    backgroundColor: '#007BFF',
    color: '#fff',
    padding: '10px 20px',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
  };

  const navStyle = {
    marginTop: '10px',
  };

  const linkStyle = {
    textDecoration: 'none',
    color: '#fff',
    margin: '0 10px',
    fontSize: '18px',
  };

  const footerStyle = {
    backgroundColor: '#333',
    color: '#fff',
    padding: '10px',
    textAlign: 'center',
    marginTop: '20px',
  };

  return (
    <div>
      {/* Header */}
      <header style={headerStyle}>
        Interview Edu AI Art App
        <nav style={navStyle}>
          <Link to="/" style={linkStyle}>Home</Link>
          <Link to="/interview" style={linkStyle}>Interview</Link>
          <Link to="/meeting-assistant" style={linkStyle}>Meeting Assistant</Link> {/* Add the link */}
          <Link to="/contact" style={linkStyle}>Contact</Link>
          
        </nav>
      </header>

      {/* Main Content */}
      <main style={{ padding: '20px', minHeight: '70vh' }}>{children}</main>

      {/* Footer */}
      <footer style={footerStyle}>
        &copy; {new Date().getFullYear()} Interview EduAiArt App. All rights reserved.
      </footer>
    </div>
  );
}
