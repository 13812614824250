import React from 'react';
import Layout from '../components/Layout';

export default function ContactPage() {
  return (
    <Layout>
      <h1>Contact Us</h1>
      <p>If you have any questions, feel free to reach out!</p>
    </Layout>
  );
}
