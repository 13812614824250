import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import InterviewPage from './pages/InterviewPage';
import ContactPage from './pages/ContactPage';
import MeetingAssistantPage from './pages/MeetingAssistantPage'; // Import the new page

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/interview" element={<InterviewPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/meeting-assistant" element={<MeetingAssistantPage />} /> {/* Add the new route */}
      </Routes>
    </Router>
  );
}

export default App;
