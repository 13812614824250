import React from 'react';
import Layout from '../components/Layout';

export default function HomePage() {
  return (
    <Layout>
      <h1>Welcome to the Interview App</h1>
      <p>Navigate to the Interview section to start a meeting or check the Contact page to reach us.</p>
    </Layout>
  );
}
